<template>
  <div id="app" class="admin">
    <LoadingScreen />

    <template v-if="$auth.isAuthenticated">
      <Menu />
      <ModalContainer />
      <router-view />
    </template>
  </div>
</template>

<script>
  import AdminContentLoder from 'mixins/admin/content-loader';
  import ModalContainer from 'components/modal-container';
  import LoadingScreen from 'components/loading-screen';
  import Menu from 'components/admin/menu';

  export default {
    name: 'Admin',
    components: { ModalContainer, LoadingScreen, Menu },
    mixins: [AdminContentLoder],

    watch: {
      $route (to) {
        window.scrollTo(window.scrollX, 0);
        this.setTitle(to);
      }
    },

    methods: {
      setTitle (route) {
        const title = route.meta && route.meta.title;
        document.title = title ? `${title} - Mateus` : this.defaultTitle;
      }
    }
  };
</script>

<style lang="stylus">
  @require '~global/reset'
  @require '~global/fonts'
  @require '~global/typo'

  html
    @extend .t-base
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

  html,
  body
    height: 100%
</style>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'

  #app
    display: flex
    flex-direction: column
    align-items: center
    min-height: 100%
    background: $off-white
    padding: 0 2rem

</style>
