<template>
  <header class="menu">
    <ul class="navigation">
      <li class="navigation-item">
        <a href="#" @click.prevent="navigate('claims')">Reklamationer</a>
      </li>

      <li class="navigation-item">
        <a href="#" @click.prevent="navigate('export')">Exportera</a>
      </li>

      <li class="navigation-item">
        <a href="#" @click.prevent="logout">Logga ut</a>
      </li>
    </ul>
  </header>
</template>

<script>
  export default {
    name: 'Menu',
    methods: {
      navigate (name) {
        if (name !== this.$router.currentRoute.name) {
          this.$router.push({ name });
        }
      },
      logout () {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    }

  };
</script>

<style lang="stylus" scoped>
  @require '~global/helpers'

  .menu
    @extend .flex-h
    padding: 1rem

  .navigation
    display: flex
    justify-content: center

  .navigation-item
    padding: 1rem 0.5rem

  .navigation-item:after
    content: "|"
    display: inline-block
    position: relative
    left: 0.5rem

  .navigation-item:last-of-type
    &:after
      display: none

</style>
