import Vue from 'vue';
import Router from 'vue-router';
import Admin from './Admin.vue';
import routes from './routes';
import AppConfig from 'lib/app-config';
import axios from 'lib/axios';
import store from './store';
import { domain, clientId, audience } from '../auth_config.json';
import { Auth0Plugin } from './auth';
import JsonExcel from 'vue-json-excel';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: '2f27fcfb97b8335c37de53163a5b8abc',
  plugins: [new BugsnagPluginVue()]
});

Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

Vue.component('DownloadExcel', JsonExcel);

Vue.config.productionTip = false;

Vue.prototype.apiURL = AppConfig.apiURLAdmin;
Vue.prototype.$http = axios;

const router = new Router({
  mode: 'history',
  routes: routes.admin
});

Vue.use(Router);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});

new Vue({
  router,
  store: store.admin,
  render: h => h(Admin)
}).$mount('#app');
