<template>
  <div :class="['loading-screen', {'is-loading': isLoading, 'is-loaded': !isLoading}]" v-if="visible">
    <LoadingIcon class="spinner" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import LoadingIcon from 'icons/loading.svg';
  export default {
    name: 'LoadingScreen',
    components: { LoadingIcon },
    data () {
      return {
        visible: true
      };
    },

    computed: {
      ...mapState(['loader']),

      isLoading () {
        return this.$auth.loading || this.loader.loading;
      }
    },

    watch: {
      isLoading (val) {
        setTimeout(() => {
          this.visible = val;
        }, 300);
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'

  .loading-screen
    @extend .flex-vh
    background: rgba(255,255,255,.8)
    width: 100vw
    height: 100vh
    position: fixed
    opacity: 1
    transition: opacity .3s
    z-index: 99

  .loading-screen.is-loaded
    opacity: 0

  .spinner
    color: $brand-yellow
    width: 50px
</style>
