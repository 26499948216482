export default {
  methods: {
    async loadAdminContent () {
      this.$store.commit('loader/setLoading', { loading: true, handler: 'contentLoader' });

      const promises = [];
      promises.push(this.$store.dispatch('claim/loadClaims'));

      try {
        await Promise.all(promises);
      } catch (err) {
        console.log(err);
      }

      this.$store.commit('loader/setLoading', { loading: false, handler: 'contentLoader' });
    }
  },

  watch: {
    isAuthenticated (isAuthenticated) {
      if (isAuthenticated) {
        this.loadAdminContent();
      }
    }
  },

  computed: {
    isAuthenticated () {
      return this.$auth.isAuthenticated;
    }
  }
};
